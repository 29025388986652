<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FF507D;"
      d="M503.172,114.759H392.828c-2.438,0-4.414,1.976-4.414,4.414v3.915
	c0,9.506-7.306,17.686-16.801,18.134c-10.138,0.478-18.509-7.602-18.509-17.635v-4.414c0-2.438-1.976-4.414-4.414-4.414H8.828
	c-4.875,0-8.828,3.953-8.828,8.828v264.828c0,4.875,3.953,8.828,8.828,8.828H348.69c2.438,0,4.414-1.976,4.414-4.414v-3.915
	c0-9.506,7.306-17.686,16.801-18.134c10.138-0.478,18.509,7.602,18.509,17.635v4.414c0,2.438,1.976,4.414,4.414,4.414h110.345
	c4.875,0,8.828-3.953,8.828-8.828V123.586C512,118.711,508.047,114.759,503.172,114.759z M379.586,344.276
	c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828v-17.655c0-4.875,3.948-8.828,8.828-8.828
	c4.879,0,8.828,3.953,8.828,8.828V344.276z M379.586,291.31c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828
	v-17.655c0-4.875,3.948-8.828,8.828-8.828c4.879,0,8.828,3.953,8.828,8.828V291.31z M379.586,238.345
	c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828V220.69c0-4.875,3.948-8.828,8.828-8.828
	c4.879,0,8.828,3.953,8.828,8.828V238.345z M379.586,185.379c0,4.875-3.948,8.828-8.828,8.828c-4.879,0-8.828-3.953-8.828-8.828
	v-17.655c0-4.875,3.948-8.828,8.828-8.828c4.879,0,8.828,3.953,8.828,8.828V185.379z"
    />
    <path
      style="fill:#D23C69;"
      d="M379.586,167.724v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c4.879,0,8.828,3.953,8.828,8.828
	v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c4.879,0,8.828,3.953,8.828,8.828v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655
	c4.879,0,8.828,3.953,8.828,8.828v17.655c0,4.875-3.948,8.828-8.828,8.828v17.655c9.75,0,17.655,7.905,17.655,17.655v4.414
	c0,2.438,1.976,4.414,4.414,4.414h110.345c4.875,0,8.828-3.953,8.828-8.828V123.586c0-4.875-3.953-8.828-8.828-8.828H392.828
	c-2.438,0-4.414,1.976-4.414,4.414v4.414c0,9.75-7.905,17.655-17.655,17.655v17.655
	C375.638,158.897,379.586,162.849,379.586,167.724z"
    />
    <polygon
      style="fill:#FFDC64;"
      points="512,247.172 194.207,247.172 194.207,114.759 176.552,114.759 176.552,247.172 0,247.172 
	0,264.828 176.552,264.828 176.552,397.241 194.207,397.241 194.207,264.828 512,264.828 "
    />
    <path
      style="fill:#FFC850;"
      d="M275.985,264.737c-5.807-4.467-5.807-13.009,0-17.474c0.039-0.03,0.078-0.061,0.117-0.09h-81.895
	v-81.895c-0.03,0.039-0.061,0.078-0.09,0.117c-4.467,5.807-13.009,5.807-17.474,0c-0.03-0.039-0.061-0.078-0.09-0.117v81.895H94.657
	c0.039,0.03,0.078,0.061,0.117,0.09c5.807,4.467,5.807,13.009,0,17.474c-0.039,0.03-0.078,0.061-0.117,0.09h81.895v81.895
	c0.03-0.039,0.061-0.078,0.09-0.117c4.467-5.807,13.009-5.807,17.474,0c0.03,0.039,0.061,0.078,0.09,0.117v-81.895h81.895
	C276.063,264.798,276.023,264.767,275.985,264.737z"
    />
    <path
      style="fill:#FFDC64;"
      d="M217.517,256c18.711-6.222,41.292-15.551,52.973-27.233c7.734-7.732,11.992-18.008,11.992-28.939
	c0-10.931-4.258-21.216-11.991-28.94c-7.724-7.733-18.008-11.991-28.94-11.991s-21.207,4.258-28.94,11.991
	c-11.681,11.681-21.011,34.262-27.233,52.973c-6.222-18.711-15.551-41.292-27.233-52.973c-7.731-7.733-18.007-11.991-28.938-11.991
	s-21.216,4.258-28.94,11.991c-7.733,7.724-11.991,18.008-11.991,28.94c0,10.931,4.258,21.207,11.991,28.94
	c11.681,11.681,34.262,21.011,52.973,27.233c-18.711,6.222-41.292,15.551-52.973,27.233c-7.733,7.732-11.991,18.007-11.991,28.938
	c0,10.931,4.258,21.216,11.991,28.94c7.724,7.733,18.008,11.991,28.94,11.991s21.207-4.258,28.94-11.991
	c11.681-11.681,21.011-34.262,27.233-52.973c6.222,18.711,15.551,41.292,27.233,52.973c7.732,7.733,18.007,11.991,28.938,11.991
	s21.216-4.258,28.94-11.991c7.733-7.724,11.991-18.008,11.991-28.94c0-10.932-4.258-21.207-11.991-28.94
	C258.809,271.551,236.23,262.222,217.517,256z M225.095,183.371c4.396-4.396,10.241-6.819,16.457-6.819s12.061,2.422,16.457,6.819
	c4.396,4.397,6.819,10.241,6.819,16.457c0,6.216-2.422,12.061-6.819,16.457c-11.577,11.577-40.526,21.725-60.293,27.38
	C203.371,223.896,213.517,194.948,225.095,183.371z M105.931,199.828c0-6.216,2.422-12.061,6.819-16.457
	c4.397-4.396,10.241-6.819,16.457-6.819c6.216,0,12.061,2.422,16.457,6.819c11.577,11.577,21.725,40.526,27.38,60.294
	c-19.767-5.655-48.715-15.801-60.294-27.38C108.353,211.887,105.931,206.044,105.931,199.828z M145.664,328.629
	c-8.793,8.793-24.12,8.793-32.914,0c-4.396-4.396-6.819-10.241-6.819-16.457c0-6.216,2.422-12.061,6.819-16.457
	c11.577-11.577,40.526-21.725,60.294-27.38C167.388,288.104,157.241,317.052,145.664,328.629z M258.008,328.629
	c-8.793,8.793-24.12,8.793-32.914,0c-11.577-11.577-21.725-40.526-27.38-60.293c19.767,5.655,48.715,15.801,60.294,27.38
	c4.396,4.396,6.819,10.241,6.819,16.457C264.828,318.388,262.406,324.233,258.008,328.629z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
